@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

/* Apply Roboto to all elements */
body {
    font-family: 'Roboto', sans-serif;
}
:root {
  --light-blue: #e9f1fa;
  --dark-blue: #00abe4;
  --darker-blue: #0077ff;
  --white: #fff;
  --bold-text-color: black;
  --light-text-color: black;
  --font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
}

@media (max-width: 1024px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 896px) {
  html {
    font-size: 85%;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 80%;
  }
}

@media (max-width: 596px) {
  html {
    font-size: 70%;
  }
}

@media (max-width: 425px) {
  html {
    font-size: 60%;
  }
}
